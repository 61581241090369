import { HttpService } from 'networking/HttpService';

export const SecureImagesService = {
  uploadImage: async (file, path) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);

    const { data } = await HttpService({
      method: 'POST',
      route: '/secure-images',
      body: formData,
    });

    return data;
  },
};
