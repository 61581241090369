import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { UserService, BuildingsService } from 'services';

class SsoLogin extends React.Component {
	state = {
		loadingStatus: 'Verifying Login...',
	};

	getToken = () => {
		const {
			match: {
				params: { id, location },
			},
			history,
			dispatch,
		} = this.props;

		let localToken = '';

		if (!id) {
			return history.push('/');
		}
		const temp = JSON.parse(localStorage.getItem('user'));
		if (temp.id) {
			return window.location.replace('/');
		}

		return fetch(`${process.env.API_URL}/sign-in`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${id}`,
			},
			body: JSON.stringify({
				email: '',
				password: '',
			}),
		})
			.then(res => res.json())
			.then(({ data: { accessToken } }) => {
				if (accessToken) {
					localToken = accessToken;
					dispatch({
						type: 'SET_USER',
						payload: accessToken,
					});

					// TODO: Why doesn't this work?
					// this.setState({ loadingStatus: 'Getting user data...' });

					// Get user data.
					return UserService.fetchMyInfo(accessToken);
				}
				throw new Error('Token not recieved');
			})
			.then(({ data }) => {
				// Store user data.
				dispatch({
					type: 'SET_USER_DATA',
					payload: data,
				});
				dispatch({
					type: 'SET_CURRENT_DISTRICT',
					payload: data.primaryBuilding,
				});

				history.push(`/${location || ''}`);
				window.location.reload();
			})
			.catch(err => {
				console.error('SSO LOGIN ERROR');
				console.error(err);
				return history.push('/');
			});
	};

	componentDidMount() {
		this.getToken();
	}

	render() {
		const { loadingStatus } = this.state;
		return (
			<>
				<LoadingSpinner size="large" message={loadingStatus} />
			</>
		);
	}
}

SsoLogin.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	dispatch: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(mapDispatchToProps);

export default compose(withConnect)(SsoLogin);
