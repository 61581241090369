import React, { useState } from 'react';
import { FileUpload, FormModal } from 'componentsV2';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';
import { SecureImagesService } from 'services';

export const AddImageModal = ({ isOpen, closeModal, flipchartId, editor }) => {
	const [loading, setLoading] = useState();
	const [selectedFile, setSelectedFile] = useState();
	const [fileError, setFileError] = useState(null);
	const [isAddVisiable, setIsAddVisiable] = useState(false);

	const onFileInputChange = (file) => {
		setSelectedFile(file);
		setFileError(null);
	};

	const uploadFile = async () => {
		try {
			setLoading(true);

			const imageData = await SecureImagesService.uploadImage(
				selectedFile,
				`flipcharts/${flipchartId}/embeds`,
			);

			// Insert the returned image at the current cursor position (if editor exists).
			const image = `<img src="${imageData.src}" width="100%" />`;
			if (editor) {
				editor.insertContent(image);
			} else {
				setLoading(false);
			}

			closeModal();
		} catch (error) {
			console.error('Error uploading file:', error);
			setLoading(false);
		}
	};

	const handleProgressCompleted = (isProgressCompleted) => {
		if (isProgressCompleted) {
			setIsAddVisiable(true);
		} else {
			setIsAddVisiable(false);
		}
	};

	return (
		<FormModal
			isOpen={isOpen}
			onClose={() => closeModal()}
			title={'Add image'}
			size="small"
			showCloseButton={false}
			loading={loading}
			customClassName={'upload-modal-container'}
			confirmBtn={{
				title: `Add`,
				onClick: uploadFile,
				disabled: false,
				className: isAddVisiable ? '' : 'add-button-hidden',
			}}
			cancelBtn={{
				title: 'Cancel',
				type: isAddVisiable ? 'tertiary' : 'primaryDefault',
				onClick: closeModal,
			}}
		>
			<div className="col-md-12">
				<FileUpload
					disabled={false}
					required={true}
					allowedFiles={['png', 'jpeg', 'gif', 'jpg']}
					allowedFilesLegend={`PNG, JPEG, GIF (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
					// defaultErrorMessage = 'Upload failed, please try again'
					label="Image"
					labelState={false}
					onFileInputChange={onFileInputChange}
					setProgressCompleted={handleProgressCompleted}
					maxFileSize={MAX_FILE_UPLOADER_SIZE}
					error={fileError ? true : false}
					defaultErrorMessage={fileError}
				/>
			</div>
		</FormModal>
	);
};
