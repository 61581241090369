import React, { useState, useContext, useRef } from 'react';
import { FormModal, Input, ToasterContext, Intent, FeaturedIcon } from 'componentsV2';
import './css/AddPrimaryContactModal.scss';
import { FirstRespondersService } from '../../../../services/FirstRespondersService';

export const AddPrimaryContactModal = ({
	loading,
	onCloseModal,
	firstResponder,
	loadFirstResponder,
	responderId,
}) => {
	const toaster = useContext(ToasterContext);
	const emailRef = useRef(null);
	const [contactInformation, setcontactInformation] = useState({
		firstName: '',
		lastName: '',
		email: '',
	});
	const [emailValidation, setEmailValidation] = useState({
		userEmail: '',
	});

	const handleChangeContactInformation = e => {
		const { name } = e.target;
		const { value } = e.target;

		setcontactInformation({
			...contactInformation,
			[name]: value,
		});
	};

	const checkEmailValidity = () => {
		// Adding HTML5 form validation API method.
		if (emailRef.current.checkValidity()) {
			setEmailValidation({
				userEmail: '',
			});
		} else {
			setEmailValidation({
				userEmail: 'Please enter a valid email address.',
			});
		}
	};

	const onSaveContactInformation = e => {
		e.preventDefault();
		FirstRespondersService.addPrimaryContact(
			responderId,
			firstResponder.data.allowed_sites_ids,
			contactInformation,
		)
			.then(resp => {
				if (resp.statusCode === 201) {
					onCloseModal();
					toaster(
						`You have successfully added the primary contact for ${
							firstResponder.data.name
						} agency.`,
						Intent.SUCCESS,
						<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
					);
					loadFirstResponder(responderId);
				}
			})
			.catch(error => {
				if (error?.error?.fields?.contact_email) {
					setEmailValidation({
						userEmail:
							'Email already exists. Verify that user is not in the system or enter a different email.',
					});
					toaster(
						`${error.error?.fields?.contact_email}`,
						Intent.DANGER,
						<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
					);
				} else {
					toaster(
						`${error?.error?.description}`,
						Intent.DANGER,
						<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
					);
				}
				console.log('error', error);
			});
	};

	return (
		<>
			<FormModal
				isOpen
				onClose={onCloseModal}
				title={'Add new primary contact'}
				showCloseButton={false}
				size={'small'}
				confirmBtn={{
					title: 'Add',
					disabled:
						!contactInformation.firstName ||
						!contactInformation.lastName ||
						!contactInformation.email,
					onClick: onSaveContactInformation,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: onCloseModal,
				}}
				loading={loading}
				scrollContent={false}
			>
				<div className="w-100 mt-1">
					<Input
						name="firstName"
						label="First name"
						placeholder="First name"
						isRequired={true}
						onChange={handleChangeContactInformation}
					/>
				</div>
				<div className="w-100 mt-3">
					<Input
						name="lastName"
						label="Last name"
						placeholder="Last name"
						isRequired={true}
						onChange={handleChangeContactInformation}
					/>
				</div>
				<div className="w-100 mt-3">
					<Input
						inputRef={emailRef}
						label="Email"
						name="email"
						type="email"
						placeholder="Email"
						isRequired={true}
						onChange={e => {
							checkEmailValidity(),
								(emailValidation.userEmail = ''),
								handleChangeContactInformation(e);
						}}
						error={emailValidation.userEmail}
						errorMessage={emailValidation.userEmail}
					/>
				</div>
				<div className="w-100 mt-3">
					<Input
						type="number"
						label="Phone number"
						name="phone"
						placeholder="Phone number..."
						onChange={handleChangeContactInformation}
					/>
				</div>
			</FormModal>
		</>
	);
};
