import { HttpService } from 'networking/HttpService';
import { decodeToken } from 'utilities';
import { SITE_FIRST_RESPONDER } from 'utilities/constants';

/** This service is to handle requests that are for when a responder is logged into 5304 */
export const RespondService = {
	getDistricts: () =>
		HttpService({
			method: 'GET',
			route: `/responders/districts/available`,
		}),

	getBuildings: districtId =>
		HttpService({
			method: 'GET',
			route: `/responders/districts/${districtId}/buildings`,
		}),

	getBuildingDrills: (districtId, buildingId) =>
		HttpService({
			method: 'GET',
			route: `/responders/districts/${districtId}/buildings/${buildingId}/drills`,
		}),

	startResponderMasqueradingSession: (districtID, buildingID, userID) =>
		HttpService({
			method: 'GET',
			route: `/responders/masquerade/${districtID}/${buildingID}/${userID}`,
		}),

	isMasquerading: () => {
		const userInfo = localStorage.getItem('masqueradingUserToken');
		const token = userInfo ? JSON.parse(userInfo).jwt : null;
		const decodedToken = token ? decodeToken(token) : 0;

		return decodedToken ? decodedToken.dist === SITE_FIRST_RESPONDER : false;
	},

	stopResponderMasqueradingSession: () =>
		HttpService({
			method: 'GET',
			route: '/responders/masquerade/stop',
		}),
};
