import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
	BootstrapedTable,
	FilterMenuFn,
	FilterMenu,
	FilterBar,
	TableCell,
	ToasterContext,
	Intent,
	FeaturedIcon,
	Input,
	sortingStyle,
	noResultFoundIcon,
	EmptyState,
} from 'componentsV2';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SearchBar } from 'components/SearchBar';
import { Icon } from '@blueprintjs/core';
import './DrillsTable.scss';
import { DrillsService, BuildingsService, SchoolYearsService, DrillTypesService } from 'services';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { formatDate, getCurrentSchoolYear } from 'utilities/dates';
import { SORTING_DIR } from 'utilities/constants';
import { TableActions } from './TableActions';
import { DateCellEdit } from './DateCellEdit';
import { formatDrillForTable, calculateDayDifference } from './utils';
import { TableStatusCell } from './TableStatusCell';

export const DrillsTable = ({
	history,
	drills,
	setDrills,
	totalRows,
	filtrationData,
	setFiltrationData,
	setIsFilterLoading,
	loadDeleteModal,
	loadInstructionsModal,
	addDrillToCalendar,
}) => {
	const dispatch = useDispatch();
	const drillsFilterationData = useSelector(state => state.route.drillsFilterationData);
	const toaster = useContext(ToasterContext);

	const updateDrills = (rowId, data) => {
		const updatedDrills = drills.map(drill => {
			if (drill.scheduleId === rowId) {
				return data;
			}
			return drill;
		});
		setDrills(updatedDrills);
	};

	const handleDateUpdate = async (row, newDate) => {
		if (newDate) {
			try {
				// Persist updated scheduled date
				// Get building
				const building = await BuildingsService.findOne(row.buildingId);
				const year = new Date().getFullYear();
				// Update or create drill depending on status
				if (row.status !== 'N/A') {
					const drillsDataToUpdate = [{ scheduleId: row.scheduleId, date: newDate }];
					const updateDrillResponse = await DrillsService.updateAndCreateDrills(
						building.id,
						year,
						drillsDataToUpdate,
					);
					updateDrills(
						row.scheduleId,
						formatDrillForTable(updateDrillResponse.updatedDrills[0]),
					);
				} else {
					const drillsDataToCreate = [
						{ drillTypeUUIDs: { value: row.drillTypeUuid }, date: newDate },
					];
					const createDrillResponse = await DrillsService.updateAndCreateDrills(
						building.id,
						year,
						[],
						drillsDataToCreate,
					);
					updateDrills(
						row.scheduleId,
						formatDrillForTable(createDrillResponse.newDrills[0], row.scheduleId),
					);
				}
				// Success toaster
				toaster(
					`Scheduled date changed for "${row.type}".`,
					Intent.SUCCESS,
					<FeaturedIcon icon="time" type="Success" />,
				);
			} catch (error) {
				toaster(
					`Error updating scheduled date for "${row.type}".`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
				console.log('Error updating scheduled date', error);
			}
		}
	};

	const tableColumns = [
		{
			key: 'typeUuid',
			dataField: 'typeUuid',
			hidden: true,
		},
		{
			key: 'actualDrillDate',
			dataField: 'actualDrillDate',
			hidden: true,
		},
		{
			key: 'type',
			dataField: 'type',
			text: 'Type',
			sort: true,
			sortCaret: sortingStyle,
			formatter: (cell, row) => (
				<TableCell
					text={row.type}
					supportText={
						row.status.toLowerCase() !== 'completed'
							? calculateDayDifference(row.scheduledDrillDate)
							: ''
					}
				/>
			),
			editable: false,
		},
		{
			key: 'buildingName',
			dataField: 'buildingName',
			text: 'Site',
			sort: true,
			sortCaret: sortingStyle,
			editable: false,
		},
		{
			key: 'scheduledDrillDate',
			dataField: 'scheduledDrillDate',
			text: 'Scheduled date',
			formatter: (cell, row) => (
				<div className="drills-table-schedule-date-cell">
					<Icon icon="calendar" className="schedule-date-cell-icon" />
					<span>{cell ? formatDate(cell) : 'Schedule drill'}</span>
				</div>
			),
			editable: true,
			editorRenderer: (editorProps, value, row) => (
				<DateCellEdit
					editorProps={editorProps}
					date={value}
					row={row}
					onSubmit={handleDateUpdate}
				/>
			),
		},
		{
			key: 'status',
			dataField: 'status',
			text: 'Status',
			formatter: (cell, row) => <TableStatusCell status={cell} />,
			editable: false,
		},
		{
			key: 'actions',
			dataField: 'scheduleId',
			text: 'Actions',
			formatter: (cell, row) => (
				<TableActions
					drill={row}
					loadDeleteModal={loadDeleteModal}
					loadInstructionsModal={loadInstructionsModal}
					addDrillToCalendar={addDrillToCalendar}
					history={history}
				/>
			),
			editable: false,
		},
	];

	// FILTERS
	// Search bar
	const onSearch = type => {
		setIsFilterLoading(true);
		setFiltrationData(oldData => ({
			...oldData,
			searchByType: type,
		}));
	};

	// Filters menu options
	const [siteOptions, setSiteOptions] = useState([]);
	const [schoolYearOptions, setSchoolYearOptions] = useState([]);
	const [drillTypeOptions, setDrillTypeOptions] = useState([]);
	const [statusOptions, setStatusOptions] = useState([]);

	const getFilterationOptions = async () => {
		// get sites
		const listBuildingsData = await BuildingsService.getAll(false, false);
		const enabledSites = listBuildingsData.filter(site => site.drillLogsEnabled == true);
		setSiteOptions(enabledSites);
		// get school years
		const listSchoolYearsData = await SchoolYearsService.fetchYears();
		const currentSchoolYear = getCurrentSchoolYear()[0];
		const filteredYears = listSchoolYearsData?.filter(year => year.value <= currentSchoolYear);
		setSchoolYearOptions(filteredYears);
		const listDrillTypesData = await DrillTypesService.getAll(false, null, null, true);
		setDrillTypeOptions(
			listDrillTypesData
				.map(drillType => ({ ...drillType, value: drillType.uuid }))
				.sort((a, b) => a.name.localeCompare(b.name)),
		);
		// get statuses
		setStatusOptions([
			{ label: 'Scheduled', value: 'scheduled' },
			{ label: 'In Progress', value: 'in_progress' },
			{ label: 'Overdue', value: 'overdue' },
			{ label: 'Completed', value: 'completed' },
		]);
	};

	// Filter functions
	const {
		filterData,
		handleChangeFilter,
		removeFilter,
		getFilterValue,
		initialFilterDrillData,
	} = FilterMenuFn();

	useEffect(() => {
		getFilterationOptions();
		// while reinitializing the filterData we want the filters saved in drillsFilterationData, therefore removing
		// the handleChangeFilterWrapper with static values and passing in the
		// drillsFilterationData to initialize filterData with the previous stored values.
		initialFilterDrillData(drillsFilterationData);
	}, []);

	useEffect(() => {
		// updated search value is stored in filtration data and hence we need to update it in drillsFilterationData(redux).
		dispatch({
			type: 'SET_DRILLS_FILTERATION_DATA',
			payload: { ...drillsFilterationData, searchByType: filtrationData.searchByType || '' },
		});
	}, [filtrationData.searchByType]);

	const handleChangeFilterWrapper = (filterName, value, filterType) => {
		setIsFilterLoading(true);
		handleChangeFilter(filterName, value, filterType);

		updateFiltrationData(filterData.filters);
	};

	const removeFilterWrapper = filterName => {
		setIsFilterLoading(true);
		removeFilter(filterName);

		updateFiltrationData(filterData.filters);
	};

	const updateFiltrationData = filters => {
		const { perPage, searchByType } = filtrationData;
		// dispatching searchByType along with other filters in filtrationData and drillsFilterationData.
		// Update the searchType whenever we are updating the filteration data. If value exists in searchType send in the value or send in ''.
		const defaultValues = {
			page: 1,
			perPage,
			searchByType: searchByType || '',
		};
		Object.entries(filters).forEach(([key, value]) => {
			defaultValues[key] = value;
		});
		// dispatching the filtration values.
		dispatch({
			type: 'SET_DRILLS_FILTERATION_DATA',
			payload: { ...defaultValues, searchByType: searchByType || '' },
		});
		setFiltrationData({ ...defaultValues, searchByType: searchByType || '' });
	};

	const filterMenuItems = [
		{
			title: 'Site',
			name: 'buildingIds',
			onchange: handleChangeFilterWrapper,
			options: siteOptions,
			multipleSelect: true,
		},
		{
			title: 'School year',
			name: 'years',
			onchange: handleChangeFilterWrapper,
			options: schoolYearOptions,
			multipleSelect: true,
			defaultValue: [getCurrentSchoolYear()[0]],
		},
		{
			title: 'Drill type',
			name: 'drillTypeUUIDs',
			onchange: handleChangeFilterWrapper,
			options: drillTypeOptions,
			multipleSelect: true,
		},
		{
			title: 'Status',
			name: 'status',
			onchange: handleChangeFilterWrapper,
			options: statusOptions,
			multipleSelect: true,
		},
		{
			title: 'Scheduled drill date',
			name: 'scheduledDate',
			content: (
				<Input
					placeholder="Select date"
					value={getFilterValue('scheduledDate')}
					onChange={e => handleChangeFilterWrapper('scheduledDate', e.target.value)}
					type="date"
				/>
			),
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		setIsFilterLoading(true);
		if (type === 'sort') {
			let sortColumn = 'type';
			switch (sortField) {
				case 'buildingName':
					sortColumn = 'building';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			setFiltrationData(oldValue => ({
				...oldValue,
				sortKey: sortColumn,
				sortDir: sortOrder,
			}));
		}
	};

	const setRowsPerPage = value => {
		setIsFilterLoading(true);
		setFiltrationData(oldData => ({ ...oldData, perPage: value }));
	};

	const setCurrentPage = value => {
		setIsFilterLoading(true);
		setFiltrationData(oldData => ({ ...oldData, page: value }));
	};

	return (
		<div className="d-flex flex-column">
			<div className="filters">
				<div className="filter-ctrls">
					<SearchBar
						placeholder="Search by type..."
						icon="search"
						onSearch={onSearch}
						value={filtrationData?.searchByType || ''}
					/>
					<FilterMenu items={filterMenuItems} getFilterValue={getFilterValue} />
				</div>
				<div className="filter-pills">
					<FilterBar
						items={filterMenuItems}
						getFilterValue={getFilterValue}
						removeFilter={removeFilterWrapper}
					/>
				</div>
			</div>
			{drills.length === 0 ? (
				<div className="drills-empty-state-container">
					<EmptyState
						header="No drills found"
						description="Your search didn't match any results. Please try again."
						icon={noResultFoundIcon}
						showCircles={false}
					/>
				</div>
			) : (
				<BootstrapedTable
					keyField="scheduleId"
					data={drills}
					columns={tableColumns}
					defaultSorted={[
						{
							dataField: 'type',
							order: SORTING_DIR.ASC,
						},
					]}
					currentPage={filtrationData.page}
					setCurrentPage={value => setCurrentPage(value)}
					rowsPerPage={filtrationData.perPage}
					setRowsPerPage={value => setRowsPerPage(value)}
					totalRows={totalRows}
					onTableChange={onTableChange}
					cellEdit={cellEditFactory({
						mode: 'click',
						blurToSave: true,
					})}
				/>
			)}
		</div>
	);
};

DrillsTable.propTypes = {
	history: PropTypes.object,
	drills: PropTypes.array,
	setDrills: PropTypes.func,
	totalRows: PropTypes.number,
	filtrationData: PropTypes.object,
	setFiltrationData: PropTypes.func,
	setIsFilterLoading: PropTypes.func,
	loadDeleteModal: PropTypes.func,
	loadInstructionsModal: PropTypes.func,
	addDrillToCalendar: PropTypes.func,
	isFirstResponder: PropTypes.bool,
};
