import React, { useState, useEffect } from 'react';
import { DrillsService } from 'services';
import { LoadingSpinner } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import './DrillLogsExport.scss';

const DrillsHeaderSection = ({ title, subtitle }) => {
	return (
		<div className="header-section-container">
			<span className="drill-title-container">{title}</span>
			<span className="drill-subtitle-container">{subtitle}</span>
		</div>
	);
};

const DrillReportHeader = ({ drill }) => {
	const givenName = drill?.log?.user?.person?.firstName + ' ' || 'N/A';
	const familyName = drill?.log?.user?.person?.lastName + ', ' || 'N/A';
	const primaryRoleTitle = `[${drill?.log?.user?.roles.map(role => role.title) || 'N/A'}]`;
	const nameString = givenName + familyName + primaryRoleTitle;
	const drillsHeadersSection1 = [
		{
			title: drill?.type,
			subtitle: drill?.buildingName,
		},
		{
			title: 'Actual drill date and time',
			subtitle: formatDate(drill?.log?.drillLoggedDate),
		},
	];

	const drillsHeadersSection2 = [
		{
			title: 'Drill scheduled date and time',
			subtitle: formatDate(drill?.scheduledDrillDate),
		},
		{
			title: 'Drill logged date and time',
			subtitle: formatDate(drill?.log?.logCompletedDate),
		},
		{ title: 'Drill logged by', subtitle: nameString },
	];

	return (
		<div className="drill-logs-export-main-container">
			<div className="header-section1-main-container">
				{drillsHeadersSection1.map((header, index) => (
					<DrillsHeaderSection key={index} {...header} />
				))}
			</div>
			<div className="header-section2-main-container">
				{drillsHeadersSection2.map((header, index) => (
					<DrillsHeaderSection key={index} {...header} />
				))}
			</div>
		</div>
	);
};

const TableCell = ({ children }) => {
	return <td className="table-cell-container">{children}</td>;
};

const TableRow = ({ question, answer }) => {
	return (
		<tr className="table-row-container">
			<TableCell>{question}</TableCell>
			<TableCell>{answer || 'N/A'}</TableCell>
		</tr>
	);
};

const DrillsTable = ({ drill }) => {
	return (
		<div>
			<table className="table-main-container">
				<thead>
					<tr className="table-head-row-container">
						<th className="table-head-container">Question</th>
						<th className="table-head-container">Answer</th>
					</tr>
				</thead>
				<tbody>
					{drill?.log?.answers?.map((item, index) => (
						<TableRow
							key={index}
							question={item.fieldName || 'N/A'}
							answer={item.type === 'datetime' ? formatDate(item.value) : item.value}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};

export const DrillLogsExport = props => {
	const params = new URLSearchParams(window?.location?.search);
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		// Fetch the query params and create a final object.
		// For now we are defaulting the year value to current school year, so commenting years and status for future when we would
		// want to add the user selected years and the status in the finalFilterationObject.
		// const years = params.get('years') || null;
		const searchByType = params.get('searchByType') || '';
		const drillTypeUUIDs = params.get('drillTypeUUIDs') || null;
		const buildingIds = params.get('buildingIds') || null;
		const scheduledDate = params.get('scheduledDate') || null;
		let finalFilterationObject = {
			// years: [2024],
			// status: ['completed'],
			sortKey: 'type',
			sortDir: 'asc',
		};
		if (scheduledDate) {
			finalFilterationObject.scheduledDate = scheduledDate;
		}
		if (searchByType) {
			finalFilterationObject.searchByType = searchByType;
		}
		if (drillTypeUUIDs) {
			const decodedDrillTypeUUIDs = decodeURIComponent(drillTypeUUIDs);
			finalFilterationObject.drillTypeUUIDs = decodedDrillTypeUUIDs.split(',');
		}
		// if (years) {
		// 	const decodedYears = decodeURIComponent(years);
		// 	const yearsArray = decodedYears.split(',');
		// 	finalFilterationObject.years = yearsArray.map(year => parseInt(year, 10));
		// }
		if (buildingIds) {
			const decodedBuildingIds = decodeURIComponent(buildingIds);
			const buildingIdsArray = decodedBuildingIds.split(',');
			finalFilterationObject.buildingIds = buildingIdsArray.map(id => parseInt(id, 10));
		}
		console.log('finalFilterationObject', finalFilterationObject);
		drillsData(finalFilterationObject);
	}, []);

	const drillsData = finalObj => {
		setLoading(true);
		fetchDrillsData(finalObj).then(res => {
			console.log('resposne', res);
			setDataIsLoaded(true);
			setData(res?.data);
			setLoading(false);
		});
	};

	const fetchDrillsData = async finalObj => {
		// Fetch the completed drills data.
		const finalDrillsData = await DrillsService.getDrillsData(finalObj);
		return finalDrillsData;
	};

	return (
		<div>
			{loading && <LoadingSpinner />}
			{!loading &&
				data &&
				data.map(item => (
					<div className="drill-export-main-container">
						<DrillReportHeader drill={item} />

						<div className="hr-container">
							<hr />
						</div>
						{item?.log?.answers?.length > 0 ? (
							<DrillsTable drill={item} />
						) : (
							<div className="no-table-container">There are no drill log fields.</div>
						)}
					</div>
				))}
			{dataIsLoaded && <div className="data-loaded">&nbsp;</div>}
		</div>
	);
};
