import React, { useEffect, useState } from 'react';
import { Modal, Button, FileUpload } from 'componentsV2';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const AddAttachmentModal = ({
	showUploadModal,
	handleCloseModal,
	handleUploadAttachment,
	attachmentModalTitle,
	attachmentModalLabel,
	modaLoading,
}) => {
	const [selectedAttachment, setSelectedAttachment] = useState();
	const [disableSave, setDisableSave] = useState(true);
	const [attachmentError, setAttachmentError] = useState(null);
	const customAttachmentFooter = (
		<div className="button-div-styles">
			<Button
				text={'Cancel'}
				type={disableSave ? 'primaryDefault' : 'tertiary'}
				intent="default"
				onClick={() => handleCloseModal(false)}
			/>
			{!disableSave && (
				<Button
					text={'Add'}
					intent="default"
					disabled={disableSave}
					onClick={e => {
						handleUploadFile(e);
					}}
				/>
			)}
		</div>
	);

	useEffect(() => {
		setDisableSave(true);
	}, [showUploadModal]);

	const onFileInputChange = e => {
		setSelectedAttachment(e);
		setAttachmentError(null);
	};
	const handleUploadFile = e => {
		e.preventDefault();
		handleUploadAttachment(selectedAttachment);
	};
	const fileUploadProgress = fileUploadProgress => {
		if (fileUploadProgress) {
			setDisableSave(false);
		} else {
			setDisableSave(true);
		}
	};
	return (
		<Modal
			isOpen
			onClose={() => handleCloseModal(false)}
			title={attachmentModalTitle}
			size="small"
			customFooter={customAttachmentFooter}
			customClassName={'attachments-modal-container'}
			loading={modaLoading}
		>
			<div className="col-md-12">
				<FileUpload
					disabled={false}
					maxFileSize={MAX_FILE_UPLOADER_SIZE}
					required={true}
					allowedFilesLegend={`PDF, PNG, JPEG, GIF, TXT, DOCS, XLSX, or XLS (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
					allowedFiles={[
						'png',
						'jpeg',
						'jpg',
						'pdf',
						'gif',
						'xlsx',
						'xls',
						'txt',
						'doc',
						'docx',
					]}
					label={attachmentModalLabel}
					labelState={false}
					onFileInputChange={onFileInputChange}
					setProgressCompleted={fileUploadProgress}
					error={attachmentError ? true : false}
					defaultErrorMessage={attachmentError}
				/>
			</div>
		</Modal>
	);
};
