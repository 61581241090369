import { HttpService, buildRoute } from 'networking/HttpService';
import { deserializeSites } from 'utilities/drills/deserializers';

export const DistrictsService = {
	async getAll(token) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/districts`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			return deserializeSites(response.data);
		} catch (error) {
			throw error;
		}
	},

	async selectDistrict(id, token) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/districts/select',
				creds: true,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: {
					districtId: id,
				},
			});
		} catch (error) {
			throw error;
		}
	},
};
