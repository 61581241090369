import React, { useState, useEffect } from 'react';
import { Button, Icon, ProgressBar, Intent } from '@blueprintjs/core';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper.jsx';
import iconWrapper from './images/wrapper.svg';
import iconWrapperError from './images/wrapper_error.svg';
import iconMusic from './images/music.svg';
import iconVideo from './images/video.svg';
import iconImage from './images/image.svg';
import './css/FileUpload.scss';
import { ElementWrapper } from '../InputWrapper/InputWrapper';

const FileUpload = ({
	disabled = false,
	required = false,
	error = false,
	defaultErrorMessage = null,
	allowedFiles = [],
	allowAllTypes = false,
	allowedFilesLegend = 'SVG, PNG, JPG or GIF (max. 800x400px)',
	showLegend = false,
	alloweFileDrop = true,
	file,
	fileForEditing = false,
	onFileInputChange,
	label,
	RightSideItem = null,
	hintText,
	identifier,
	labelState = true,
	maxFileSize,
	DeleteOnConfirm = false,
	setProgressCompleted = () => {},
	onDeleteFile = deletedFile => {},
	onDownloadClickHandler = () => {},
	onReplaceAction = () => {},
	showDownloadAction = false,
	showReplaceAction = true,
	showProgressBar = true,
	showDeleteBtn = true,
	downloadText = 'Download',
	setHasError = () => {},
}) => {
	const [selectedFile, setSelectedFile] = useState(file || null);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadError, setUploadError] = useState(error);
	const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
	const [inputId, setInputId] = identifier ? useState(identifier) : useState('file-input');

	useEffect(() => {
		if (fileForEditing === true) {
			setUploadProgress(100);
		}
		setSelectedFile(file);
	}, [fileForEditing, file]);

	const handleClick = event => {
		if (disabled) return;
		// Forcing the input to be empty in case same file is selected
		document.getElementById(inputId).value = '';
		document.getElementById(inputId).click();
	};

	const handleFileChange = e => {
		setUploadError(false);
		setHasError(false);
		const file = e.target.files[0];
		handleFileUpload(file);
	};

	const getIconForFileType = fileType => {
		const mimeType = fileType ? fileType.split('/')[0] : '';
		switch (mimeType) {
			case 'image':
				return iconImage;
			case 'audio':
				return iconMusic;
			case 'video':
				return iconVideo;
			default:
				return '';
		}
	};

	const getFileExtension = file => {
		if (typeof file !== 'object' || file === null) {
			return null;
		}
		const parts = file.name ? file.name.split('.') : null;
		if (!parts || parts.length === 1) {
			return null;
		}
		return parts.pop();
	};

	const handleFileUpload = file => {
		setSelectedFile(file);
		if (
			!allowAllTypes &&
			!allowedFiles
				.map(extension => extension.toLowerCase())
				.includes(getFileExtension(file).toLowerCase())
		) {
			setUploadError(true);
			setHasError(true);
			setErrorMessage('This file type is not supported.');
			return;
		}

		if (maxFileSize && file.size > maxFileSize * 1024 * 1024) {
			setUploadError(true);
			setHasError(true);
			setErrorMessage(`File size exceeds the limit of ${maxFileSize}MB`);
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			const base64Data = reader.result;
			if (typeof onFileInputChange === 'function') {
				onFileInputChange(file, base64Data);
			}
		};
		animateAttachment(file);
	};

	const handleFileDrop = e => {
		if (disabled || !alloweFileDrop) return;
		setUploadError(false);
		setHasError(false);
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		handleFileUpload(file);
	};

	const handleReplace = () => {
		setUploadProgress(0);
		setSelectedFile(null);
		setProgressCompleted(false);
		handleClick();
		onReplaceAction();
	};

	const handleReUpload = () => {
		setUploadError(false);
		setHasError(false);
		setSelectedFile(null);
		setProgressCompleted(false);
		handleClick();
	};

	const handleDelete = () => {
		if (!DeleteOnConfirm) {
			onDeleteFile(selectedFile);
			setProgressCompleted(false);
			setUploadProgress(0);
			setSelectedFile(null);
			// Forcing the input to be empty in case same file is selected
			document.getElementById(inputId).value = '';
			if (typeof onFileInputChange === 'function') {
				// Forcing the BE to DELETE the media file attached to the Scenario
				onFileInputChange({ name: '', type: '' }, null);
			}
		} else {
			onDeleteFile(selectedFile);
		}
	};

	const animateAttachment = file => {
		let progress = 0;
		const interval = setInterval(() => {
			setUploadProgress(prevProgress => {
				progress += 10;
				if (progress == 100) {
					clearInterval(interval);
					setProgressCompleted(true);
				}
				return progress;
			});
		}, Math.round((file.size / 6000000) * 500));
	};

	const handleDragOver = e => {
		e.preventDefault();
	};

	const displayFileSize = () => {
		const sizeInBytes = selectedFile.size;

		if (sizeInBytes < 1024) {
			return sizeInBytes + ' bytes';
		} else if (sizeInBytes < 1024 * 1024) {
			return (sizeInBytes / 1024).toFixed(2) + ' KB';
		} else if (sizeInBytes < 1024 * 1024 * 1024) {
			return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
		}

		return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
	};

	return (
		<InputWrapper
			label={label}
			isRequired={required}
			error={error}
			errorMessage={errorMessage}
			hasHintText={hintText && hintText.length > 0 ? true : false}
			hintText={hintText}
			labelState={labelState}
			RightSideItem={RightSideItem}
			customClass="full-width"
		>
			<ElementWrapper>
				<div className="upload-container">
					<div
						className={`file-drop-zone ${disabled ? 'disabled' : ''} ${
							uploadError ? 'error' : ''
						} ${
							uploadProgress === 0 || selectedFile === null
								? 'border-gray-100-dashed'
								: 'border-gray-50'
						}`}
						onDrop={handleFileDrop}
						onDragOver={handleDragOver}
					>
						{selectedFile ? (
							<div className="file-details-wrapper">
								<div className="file-icon">
									<svg className="frame">
										<image
											href={!uploadError ? iconWrapper : iconWrapperError}
											width="95%"
											height="100%"
										/>
									</svg>
									{getIconForFileType(selectedFile.type).length > 0 ? (
										<svg className="inner">
											<image
												href={getIconForFileType(selectedFile.type)}
												width="100%"
												height="100%"
											/>
										</svg>
									) : (
										<div
											className={`inner-text ${getFileExtension(
												selectedFile,
											)}`}
										>
											{getFileExtension(selectedFile)
												? getFileExtension(selectedFile).toUpperCase()
												: ''}
										</div>
									)}
								</div>
								<div className="file-details">
									<div className="file-name-container">
										<div className="file-name">
											<p>{selectedFile.name}</p>
										</div>
										{!uploadError && showDeleteBtn && (
											<Button
												icon="trash"
												title="Remove"
												minimal={true}
												small={true}
												type="button"
												onClick={handleDelete}
												className="trash-icon"
											/>
										)}
									</div>
									<p className="file-size">
										{!uploadError
											? showLegend
												? allowedFilesLegend
												: displayFileSize()
											: errorMessage}
									</p>
									{!uploadError && showProgressBar && (
										<div className="progress-container">
											<ProgressBar
												value={uploadProgress / 100}
												stripes={false}
												intent="success"
												animate={false}
											/>
											<span className="progress-label">
												{uploadProgress}%
											</span>
										</div>
									)}
									{showDownloadAction && (
										<Button
											icon="cloud-download"
											text={downloadText}
											minimal={true}
											fill={false}
											onClick={onDownloadClickHandler}
										/>
									)}
									{showReplaceAction &&
										uploadProgress === 100 &&
										!uploadError && (
											<Button
												icon="reset"
												onClick={handleReplace}
												text="Replace"
												minimal={true}
												fill={false}
											/>
										)}
									{uploadError && (
										<Button
											onClick={handleReUpload}
											text="Re-upload the file"
											minimal={true}
											fill={false}
											intent={Intent.DANGER}
										/>
									)}
								</div>
							</div>
						) : (
							<div className="file-input-wrapper">
								<Button
									className={`${disabled ? 'disabled' : ''}`}
									onClick={handleClick}
									outlined={false}
									minimal={true}
								>
									<Icon className="upload-icon" icon="cloud-upload" />
								</Button>
								<label htmlFor={inputId} className="file-upload-label">
									<div className="action-line">
										<a className={`upload-link ${disabled ? 'disabled' : ''}`}>
											Click to upload
										</a>
										<span> or drag and drop</span>
									</div>
									<p className="supporting-text">{allowedFilesLegend}</p>
								</label>
							</div>
						)}
						<input
							type="file"
							onChange={handleFileChange}
							style={{ display: 'none' }}
							id={inputId}
							disabled={disabled}
						/>
					</div>
				</div>
			</ElementWrapper>
		</InputWrapper>
	);
};

export { FileUpload };
