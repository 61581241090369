import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	ActionsButton,
	Breadcrumb,
	FeaturedIcon,
	Badge,
	ToasterContext,
	Intent,
	PageHeader,
	LoadingModal,
	ConfirmationModal,
	Tooltip,
	AlertModal,
} from 'componentsV2';
import { format } from 'date-fns';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import './styles.scss';
import { AddSummaryModal } from './components/AddSummaryModal';
import { AssessmentBody } from './components/AssessmentBody';
import { AddAttachmentModal } from './components/AddAttachmentModal';
import { AssessmentService } from '../../../../services/AssessmentService';
import { DeleteDrillTypeModal } from '../../../DrillsV2/components/Settings/StateRequirements/components/DeleteDrillTypeModal';

export const ViewAssessment = ({ history, location, match }) => {
	const assessmentId = match ? match.params.id : null;
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(null);
	const [viewAssessmentData, setViewAssessmentData] = useState(location?.state?.data);
	const [assessmentSectionsData, setAssessmentSectionsData] = useState(null);
	const [assessmentAttachments, setAssessmentAttachments] = useState([]);
	const [updatedSectionsList, setUpdatedSectionsList] = useState([]);
	const [assessSettings, setAssessSettings] = useState(undefined);
	const [canEdit, setCanEdit] = useState(false);
	const [isLocked, setIsLocked] = useState(false);
	const [isComplete, setIsComplete] = useState();
	const [hasUnansweredQuestions, setHasUnansweredQuestions] = useState(false);
	const [modaLoading, setModalLoading] = useState(false);

	const toaster = useContext(ToasterContext);

	// Permissions
	const permissions = useSelector(state => state.route.permissions);
	const districtId = useSelector(state => state.route.districtId);
	const editPermissionBuilding = checkPermission(
		'site_assess_edit',
		permissions,
		viewAssessmentData?.building?.id,
	);
	const editPermissionDistrict = checkPermission('site_assess_edit', permissions, districtId);

	const breadCrumbs = [
		{ label: 'Assess', url: '/assessV2' },
		{
			label: canEdit ? 'View or edit assessment' : 'View assessment',
		},
	];

	const actionsLabel = [
		{
			label: 'Edit Summary',
			icon: 'edit',
			onModalOpen: 'add_summary',
		},
		{ label: 'Add attachment', icon: 'paperclip', onModalOpen: 'add_attachment' },
		{ label: 'Download', icon: 'cloud-download', onModalOpen: 'download_assessment' },
		{ label: 'Delete', icon: 'trash', onModalOpen: 'delete_assessment' },
	];

	useEffect(() => {
		getAssessmentAttachmentsData();
		AssessmentService.getAssessmentSections(assessmentId).then(res => {
			handleUpdateAnswerCount(res.data.sections);
			setAssessmentSectionsData(res);
			setViewAssessmentData(res.data);
			// Set the assessment status with the API response when the application data is loaded as the data stored
			// location.state is lost when navigated out of the application hence setting the isComplete to false even
			// when the assessment is marked as complete.
			setIsComplete(res?.data?.status === 'Completed');
		});

		// get assess settings and set permission
		AssessmentService.getSettings(districtId).then(
			({ data: { assessIsLocked, assessForceCompletion } }) => {
				setAssessSettings({ assessIsLocked, assessForceCompletion });
				const lockStatus = assessIsLocked && isComplete;
				setIsLocked(lockStatus);
				setCanEdit(lockStatus ? !!editPermissionDistrict : !!editPermissionBuilding);
			},
		);
	}, []);

	const handleCloseModal = () => {
		setOpenModal(null);
	};

	// Handle when a question is answered, set complete state on if any sections
	// have unanswered questions.
	const handleUpdateAnswerCount = sections => {
		const status = !!sections.find(
			({ answerCount, questionCount }) => questionCount !== answerCount,
		);
		setHasUnansweredQuestions(status);
	};

	const submitSummary = summary => {
		AssessmentService.submitAssessSummary(assessmentId, summary)
			.then(res => {
				setViewAssessmentData({
					...viewAssessmentData,
					assessmentSummary: res?.data?.assessmentSummary,
				});
				toaster(
					`You have successfully added this summary.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error adding summary. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const getAssessmentAttachmentsData = () => {
		AssessmentService.getAssessAttachmentsData(assessmentId).then(res => {
			setAssessmentAttachments(res?.data);
		});
	};

	const uploadAssessmentAttachment = fileSelected => {
		setModalLoading(true);
		AssessmentService.uploadAssessmentAttachment(assessmentId, fileSelected)
			.then(() => {
				getAssessmentAttachmentsData();
				toaster(
					`You have successfully added this attachment.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error adding this attachment. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				setModalLoading(false);
				handleCloseModal();
			});
	};

	const deleteAssessmentAttachment = () => {
		AssessmentService.deleteAttachment(assessmentId, openModal?.attachmentFileId)
			.then(() => {
				getAssessmentAttachmentsData();
				toaster(
					`You have successfully deleted this attachment.`,
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error deleting this attachment. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const getSectionIds = () => {
		return (updatedSectionsList.length > 0
			? updatedSectionsList
			: assessmentSectionsData.data.sections
		)
			.filter(section => section.isHidden === false)
			.map(section => {
				return section.id;
			});
	};

	const onDownloadAssessment = () => {
		const sectionIds = getSectionIds();
		if (sectionIds.length > 0) {
			AssessmentService.downloadAssessment(assessmentId, sectionIds)
				.then(resp => {
					const filename = `${viewAssessmentData?.name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/pdf',
					});
					const csvURL = window.URL.createObjectURL(data);
					const fileLink = document.createElement('a');
					fileLink.href = csvURL;
					fileLink.setAttribute('download', `${filename}.pdf`);
					fileLink.click();
				})
				.catch(err => {
					toaster(
						`There was an error downloading the assessment. Please try again.`,
						Intent.DANGER,
						<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
					);
					console.log(err);
				})
				.finally(() => {
					handleCloseModal();
				});
		} else {
			handleCloseModal();
			toaster(
				`There are no sections to download.`,
				Intent.DANGER,
				<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
			);
		}
	};

	const checkModalActions = actions => {
		setOpenModal(actions);
		if (actions === 'download_assessment') {
			return onDownloadAssessment();
		}
	};
	const onClickCompleteIncompleteAssesment = () => {
		setModalLoading(true);
		AssessmentService.markCompleteIncomplete(assessmentId, !isComplete)
			.then(() => {
				history.push(`/assessV2`);
				setIsComplete(!isComplete);
				toaster(
					`You have successfully marked ${viewAssessmentData?.name} as ${
						isComplete ? 'incomplete' : 'complete'
					}.`,
					isComplete ? Intent.NONE : Intent.SUCCESS,
					isComplete ? <FeaturedIcon icon="ban-circle" size="md" type="Gray" /> : '',
				);
			})
			.catch(() => {
				toaster(
					`There was an error marking the assessment. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				handleCloseModal();
				setModalLoading(false);
			});
	};

	const handleCompleteIncomplete = () => {
		if (!isComplete && assessSettings.assessForceCompletion && hasUnansweredQuestions) {
			setOpenModal('incomplete_notice');
		} else {
			setOpenModal('complete_incomplete');
		}
	};

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="View_Assessment__Container">
					<div className="breadcrumb-container">
						<Breadcrumb items={breadCrumbs} />
						<Button
							text="Exit"
							onClick={() => {
								history.push(`/assessV2`);
							}}
						/>
					</div>
					<div className="title-container">
						<div className="page-title-container">
							<div>
								<PageHeader
									title={viewAssessmentData?.name || 'Assessment'}
									subtitle={viewAssessmentData?.building?.name}
								/>
							</div>
							<div className="badge-container">
								<Badge
									key="sm-Gray"
									type="Gray"
									size="lg"
									text={`${
										viewAssessmentData?.year
									} - ${viewAssessmentData?.year + 1}`}
								/>
							</div>
						</div>

						<div className="actions-button-main-container">
							<div className="button-container">
								{isLocked && (
									<div className="locked-assessment-badge">
										<Tooltip
											tooltipContent="This assessment is locked and can’t be edited. Contact your District Administrator if you need this assessment to be unlocked to make changes."
											theme="dark"
											position="bottom"
										>
											<Badge
												text="Locked Assessment"
												type="BlueDark"
												size="lg"
											/>
										</Tooltip>
									</div>
								)}
								<Button
									disabled={canEdit ? false : true}
									text={isComplete ? 'Mark as incomplete' : 'Mark as complete'}
									onClick={handleCompleteIncomplete}
								/>
								<div className="actions-button-container">
									<ActionsButton
										disabled={canEdit ? false : true}
										elementSelector={
											<Button
												type="tertiary"
												size="md"
												icon="more"
												disabled={canEdit ? false : true}
											/>
										}
									>
										{actionsLabel.map((action, index) => (
											<Button
												large
												text={action.label}
												icon={action.icon}
												size="md"
												key={index}
												type="ghostDefault"
												intent="default"
												wrapperClass="displayBlock"
												className="actionButtonElement"
												onClick={() => {
													checkModalActions(action.onModalOpen);
												}}
											/>
										))}
									</ActionsButton>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="info-main-container">
						<div className="info-icon-container">
							<FeaturedIcon
								icon="info-sign"
								size="md"
								type="Primary"
								className="icon"
							/>
						</div>
						<div className="info-text-main-container">
							<span className="info-text-sub-container">
								{`You are currently creating an assessment with the ${
									viewAssessmentData?.name
								} for the ${viewAssessmentData?.year} - ${viewAssessmentData?.year +
									1} school year.`}
							</span>
						</div>
					</div> */}
					<div className="summary-main-container">
						<span className="summary-title-container">Summary</span>
						<div
							className="summary-text-container"
							dangerouslySetInnerHTML={{
								__html:
									viewAssessmentData?.assessmentSummary ||
									'There is no summary for this template.',
							}}
						/>
					</div>
					<div className="attachments-title-container">
						<span className="attachments-text-container">Attachments</span>
						<div className="attachment-badge-container">
							{assessmentAttachments?.map(attachment => (
								<Badge
									key={attachment?.id}
									type="Gray"
									size="lg"
									text={attachment?.fileName}
									hasCloseIcon
									onClick={() => {
										setOpenModal({
											modalToOpen: 'delete_attachment',
											attachmentFileId: attachment?.id,
										});
									}}
								/>
							))}
						</div>
					</div>
					{openModal === 'complete_incomplete' && (
						<ConfirmationModal
							isOpen
							loading={modaLoading}
							title={`Are you sure you want to mark ${viewAssessmentData?.name} as ${
								isComplete ? 'incomplete' : 'complete'
							}?`}
							subtitle={`You can mark it as ${
								isComplete ? 'complete' : 'incomplete'
							} at a later time.`}
							showCloseButton={false}
							onClose={handleCloseModal}
							cancelBtn={{
								title: 'No, go back',
								onClick: handleCloseModal,
							}}
							confirmBtn={{
								title: isComplete
									? 'Yes, mark as incomplete'
									: 'Yes, mark as complete',
								onClick: onClickCompleteIncompleteAssesment,
								type: 'primaryDefault',
							}}
						/>
					)}
					{openModal === 'incomplete_notice' && (
						<AlertModal
							isOpen
							title="This assessment is incomplete."
							subtitle="This assessment will be marked as incomplete until all questions are answered."
							onClose={handleCloseModal}
							icon="error"
							iconColor="warning"
							alertConfirmButton={
								<Button
									text="OK"
									type="primaryDefault"
									size="md"
									onClick={handleCloseModal}
								/>
							}
						/>
					)}
					{openModal === 'add_summary' && (
						<AddSummaryModal
							isOpen
							submitSummary={submitSummary}
							handleCloseModal={handleCloseModal}
							summaryData={viewAssessmentData?.assessmentSummary}
							modalTitle={
								viewAssessmentData?.assessmentSummary
									? 'Edit Summary'
									: 'Add Summary'
							}
							modalSubTitle={viewAssessmentData?.name}
						/>
					)}
					{openModal === 'add_attachment' && (
						<AddAttachmentModal
							isOpen
							handleCloseModal={handleCloseModal}
							handleUploadAttachment={uploadAssessmentAttachment}
							modaLoading={modaLoading}
							attachmentModalTitle="Attach image or file"
							attachmentModalLabel="Image or file"
						/>
					)}
					{openModal?.modalToOpen === 'delete_attachment' && (
						<DeleteDrillTypeModal
							isOpen
							onCloseModal={handleCloseModal}
							titleText="Are you sure you want to delete this attachment?"
							buttonText="Delete it"
							onClickDelete={deleteAssessmentAttachment}
						/>
					)}
					{openModal === 'download_assessment' && (
						<LoadingModal
							isOpen
							loading
							text="Downloading"
							onClose={handleCloseModal}
						/>
					)}
					<AssessmentBody
						setUpdatedSectionsList={setUpdatedSectionsList}
						assessmentId={assessmentId}
						getSectionsData={assessmentSectionsData}
						viewOnly={!canEdit}
						handleUpdateAnswerCount={handleUpdateAnswerCount}
					/>
				</div>
			)}
		</>
	);
};

ViewAssessment.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};
