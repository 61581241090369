/**
 * @deprecated
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button } from 'components/Button';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

class ImageDialog extends React.Component {
	state = {
		loading: false,
	};

	render() {
		const { isOpen, closeImages } = this.props;
		const { loading } = this.state;
		return (
			<>
				<Dialog isOpen={isOpen} onClose={closeImages}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<div>
								<TitleWrap>
									<Title>Add Image</Title>
								</TitleWrap>
								<hr />
							</div>
							<div className="mx-2">
								<div className="input-group mb-3">
									<div className="custom-file">
										<input
											type="file"
											className="custom-file-input"
											id="uploadFile"
											onChange={this.uploadFile}
										/>
										<label
											className="custom-file-label"
											htmlFor="inputGuploadFileroupFile01"
										>
											Choose file
										</label>
									</div>
								</div>
							</div>
							<div className="mx-3 mt-3 d-flex justify-content-end">
								<Button text="Close" intent="secondary" onClick={closeImages} />
							</div>
						</>
					)}
				</Dialog>
			</>
		);
	}
}

ImageDialog.propTypes = {
	isOpen: PropTypes.bool,
	insertImage: PropTypes.func,
	closeImages: PropTypes.func,
};

export default ImageDialog;
