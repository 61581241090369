import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Modal, ToasterContext, StepsBar, InputWrapper, myClasses, EmptyState } from 'componentsV2';
import { RollCallAndReunificationContext } from '../RollCallAndReunification';
import { RollCallDetailsContext } from '../../RollCallDetails';
import {
	ALARM_STAFF_STATUS_ABSENT,
	ALARM_STAFF_STATUS_ACCOUNTED,
	ALARM_STAFF_STATUS_EXCEPTION,
	ALARM_STAFF_STATUS_NEED_ASSISTANCE,
	ALARM_STAFF_STATUS_UNACCOUNTED,
	ALARM_STUDENT_STATUS_ABSENT,
	ALARM_STUDENT_STATUS_ACCOUNTED,
	ALARM_STUDENT_STATUS_EXCEPTION,
	ALARM_STUDENT_STATUS_NEED_ASSISTANCE,
	ALARM_STUDENT_STATUS_TRANSFERED,
	ALARM_STUDENT_STATUS_UNACCOUNTED,
	ROLL_CALL_TAB_MY_CLASSES,
	ROLL_CALL_TAB_STAFF,
	ROLL_CALL_TAB_STUDENTS,
	ROLL_CALL_TAB_VISITORS,
	STATUS_REUNIFICATION_CANCELED,
	STATUS_REUNIFICATION_CHECKIN,
	STATUS_REUNIFICATION_CHECKIN_CANCELED,
	STATUS_REUNIFICATION_REUNIFIED,
	STATUS_REUNIFICATION_REUNIFIED_CANCELED,
} from '../../../consts';
import { RapidAlarmService } from 'services';
import { Intent } from '@blueprintjs/core';
import { StudentInfo } from './StudentInfo';
import { DateTime } from 'luxon';
import { formatDate } from 'utilities/dates';
import './css/StudentHistoryModal.scss';
import { REUNIFICATION_STUDENTS_LIST } from '../Reunification/consts';

export const StudentHistoryModal = ({ contentToShow }) => {
	const { closeModal, selectedStudent } = useContext(RollCallAndReunificationContext);
	const { alarmId } = useContext(RollCallDetailsContext);
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(true);
	const [statusesList, setStatusesList] = useState([]);
	const [notesList, setNotesList] = useState([]);

	const studentType = useMemo(() => {
		switch (contentToShow) {
			case ROLL_CALL_TAB_MY_CLASSES:
			case ROLL_CALL_TAB_STUDENTS:
			case REUNIFICATION_STUDENTS_LIST:
				return 'Student';
			case ROLL_CALL_TAB_STAFF:
				return 'Staff';
			case ROLL_CALL_TAB_VISITORS:
				return 'Visitor';
		}
		return '';
	}, []);

	const studentName = useMemo(
		() =>
			studentType === 'Staff' || studentType === 'Visitor'
				? selectedStudent.person?.fullName
				: selectedStudent.displayName,
		[],
	);

	const STATUS_LIST_CONVERSION = {
		[ALARM_STUDENT_STATUS_ACCOUNTED]: 'Accounted for',
		[ALARM_STUDENT_STATUS_NEED_ASSISTANCE]: 'Needs assistance',
		[ALARM_STUDENT_STATUS_ABSENT]: 'Not on campus',
		[ALARM_STUDENT_STATUS_EXCEPTION]: 'Exception',
		[ALARM_STUDENT_STATUS_UNACCOUNTED]: 'No status',
		[ALARM_STUDENT_STATUS_TRANSFERED]: 'Transfer to reunification',
		[ALARM_STAFF_STATUS_ACCOUNTED]: 'Accounted for',
		[ALARM_STAFF_STATUS_NEED_ASSISTANCE]: 'Needs assistance',
		[ALARM_STAFF_STATUS_ABSENT]: 'Not on campus',
		[ALARM_STAFF_STATUS_EXCEPTION]: 'Exception',
		[ALARM_STAFF_STATUS_UNACCOUNTED]: 'No status',
		[STATUS_REUNIFICATION_CANCELED]: 'Reunification canceled',
		[STATUS_REUNIFICATION_CHECKIN]: 'Checked in to reunification',
		[STATUS_REUNIFICATION_CHECKIN_CANCELED]: 'Undo check-in from reunification',
		[STATUS_REUNIFICATION_REUNIFIED]: 'Reunified',
		[STATUS_REUNIFICATION_REUNIFIED_CANCELED]: 'Undo reunify',
	};

	useEffect(() => {
		const getHistory = () => {
			if (contentToShow === ROLL_CALL_TAB_STAFF) {
				getStaffHistory();
			} else if (contentToShow === ROLL_CALL_TAB_VISITORS) {
				getVisitorHistory();
			} else {
				getStudentHistory();
			}
		};

		getHistory();
	}, []);

	const getStaffHistory = () => {
		RapidAlarmService.getStaffHistory(alarmId, selectedStudent.id)
			.then((res) => {
				if (res.statusCode === 201) {
					setStatusesList(
						res.data.statuses.map((status) => {
							const createdBy = status.createdBy?.person?.fullName;
							const date = formatDate(status.createdAt, DateTime.DATETIME_MED);
							const convertedStatus = STATUS_LIST_CONVERSION[status.status];
							return {
								title: convertedStatus,
								subtitle: `By ${createdBy} on ${date}`,
								passed: true,
								active: false,
							};
						}),
					);
					setNotesList(
						res.data.notes.map((note) => {
							const createdBy = note.creator?.person?.fullName;
							const date = formatDate(note.createdAt?.date, DateTime.DATETIME_MED);
							return {
								title: `${createdBy} on ${date}`,
								subtitle: note.text,
								passed: true,
								active: false,
							};
						}),
					);
				}
			})
			.catch((err) => toaster(err?.error?.description, Intent.DANGER))
			.finally(() => {
				setLoading(false);
			});
	};

	const getVisitorHistory = () => {
		RapidAlarmService.getVisitorHistory(alarmId, selectedStudent.id)
			.then((res) => {
				if (res.statusCode === 200) {
					setStatusesList(
						res.data.statuses.map((status) => {
							const createdBy = status.createdBy?.person?.fullName;
							const date = formatDate(status.createdAt, DateTime.DATETIME_MED);
							const convertedStatus = STATUS_LIST_CONVERSION[status.status];
							return {
								title: convertedStatus,
								subtitle: `By ${createdBy} on ${date}`,
								passed: true,
								active: false,
							};
						}),
					);
					setNotesList(
						res.data.notes.map((note) => {
							const createdBy = note.creator?.person?.fullName;
							const date = formatDate(note.createdAt?.date, DateTime.DATETIME_MED);
							return {
								title: `${createdBy} on ${date}`,
								subtitle: note.text,
								passed: true,
								active: false,
							};
						}),
					);
				}
			})
			.catch((err) => toaster(err?.error?.description, Intent.DANGER))
			.finally(() => {
				setLoading(false);
			});
	};

	const getStudentHistory = () => {
		RapidAlarmService.getStudentHistory(alarmId, selectedStudent.id)
			.then((res) => {
				if (res.statusCode === 201) {
					setStatusesList(
						res.data.statuses.map((status) => {
							const createdBy = status.createdBy?.person?.fullName;
							const date = formatDate(status.createdAt, DateTime.DATETIME_MED);
							const convertedStatus = STATUS_LIST_CONVERSION[status.status];
							return {
								title: convertedStatus,
								subtitle: `By ${createdBy} on ${date}`,
								passed: true,
								active: false,
							};
						}),
					);
					setNotesList(
						res.data.notes.map((note) => {
							const createdBy = note.creator?.person?.fullName;
							const date = formatDate(note.createdAt?.date, DateTime.DATETIME_MED);
							return {
								title: `${createdBy} on ${date}`,
								subtitle: note.text,
								passed: true,
								active: false,
							};
						}),
					);
				}
			})
			.catch((err) => toaster(err?.error?.description, Intent.DANGER))
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Modal
			isOpen
			onClose={closeModal}
			showCloseButton
			title={studentType + ' history'}
			loading={loading}
		>
			<div className="roll-call-student-history-modal-container">
				<section className="roll-call-student-history-modal-container-info">
					<StudentInfo label={studentType} value={studentName} />
					{studentType === 'Student' && (
						<StudentInfo label="Grade" value={selectedStudent.grade} />
					)}
				</section>
				<section className="roll-call-student-history-modal-container-status">
					{statusesList.length === 0 && (
						<EmptyState
							header={`This ${studentType.toLowerCase()} does not have a status yet.`}
							bodyClass="m-0 h-auto position-relative mw-100"
							icon={myClasses}
							showCircles={false}
						/>
					)}
					{!!statusesList.length && (
						<StepsBar orientation="vertical" steps={statusesList} iconCircle={false} />
					)}
				</section>
				<section className="roll-call-student-history-modal-container-notes">
					<InputWrapper label="Notes" labelState={false} />
					{notesList.length === 0 && (
						<EmptyState
							header="No notes were added yet."
							bodyClass="m-0 h-auto position-relative mw-100"
							icon={myClasses}
							showCircles={false}
						/>
					)}
					{!!notesList.length && (
						<StepsBar
							orientation="vertical"
							steps={notesList}
							iconCircle={false}
							stepBarType="notes"
						/>
					)}
				</section>
			</div>
		</Modal>
	);
};
