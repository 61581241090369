export const isNotEmpty = str => {
	if (!str || str.trim() === '') {
		return false;
	}

	return true;
};

export const isObjNotEmpty = obj => !!Object.keys(obj).length; // This function is dumb, use isObjEmpty

export const isObjEmpty = obj => Object.keys(obj).length === 0;

/** Allows special chars in the email. If in use the email (being sent in the url) needs to be encoded with encodeURIComponent before being sent to the api.
 * @param {string} email
 * @returns {boolean}
 */

/**
 * @deprecated Email validation using regex to be removed and switch to default HTML standard form validation.
 */
export const isValidEmail = email => {
	if (/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return true;
	}

	return false;
};

export const isValidPhone = phone => {
	if (phone && phone.trim().length > 0 && phone.trim().length < 10) {
		return false;
	}

	return true;
};

export const AllowOnlyNumeric = e => {
	/**
	 * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
	 * */
	const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

	return !e.key.match(regex) && e.preventDefault();
};
