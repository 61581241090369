import { HttpService, buildRoute } from 'networking/HttpService';
import { format, formatDistance } from 'date-fns';
import { deserializeDrills } from '../utilities/drills/deserializers';
import { formatDateForService } from '../utilities/dates';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const DrillsService = {
	getWithParams: async (params = {}) => {
		let route = `/drills?sortKey=${params.sortKey || 'type'}`;

		if (params.page) {
			route += `&page=${params.page}`;
		}

		if (params.perPage) {
			route += `&perPage=${params.perPage}`;
		}

		if (params.sortDir) {
			route += `&sortDir=${params.sortDir}`;
		}

		if (params.buildingIds) {
			params.buildingIds.forEach(buildingId => {
				route += `&buildingIds[]=${buildingId}`;
			});
		}

		if (params.years) {
			params.years.forEach(year => {
				route += `&years[]=${year}`;
			});
		}

		if (params.drillTypeUUIDs) {
			params.drillTypeUUIDs.forEach(drillTypeUUID => {
				route += `&drillTypeUUIDs[]=${drillTypeUUID}`;
			});
		}

		if (params.status) {
			route += `&status=${params.status}`;
		}

		if (params.scheduledDate) {
			route += `&scheduledDate=${params.scheduledDate}`;
		}

		if (params.searchByType) {
			route += `&searchByType=${params.searchByType}`;
		}

		return HttpService({
			method: 'GET',
			route,
		});
	},
	getWithPostParams: async (params = {}) =>
		HttpService({
			method: 'POST',
			route: '/drills-list',
			body: params,
		}),
	getScheduledDrill: async scheduleId => {
		const response = await HttpService({
			method: 'GET',
			route: `/drills/${scheduleId}`,
		});

		return response.data;
	},
	downloadDrillsReport: async (params = {}) =>
		HttpService({
			method: 'POST',
			route: '/drills/download',
			body: params,
		}),
	getFormFields: scheduleId => {
		if (!Number.isInteger(scheduleId) || scheduleId < 0) {
			throw new Error(
				`'scheduleId' needs to be a positive integer/number, not a ${typeof scheduleId}`,
			);
		}

		return HttpService({
			method: 'GET',
			route: `/drills/${scheduleId}/form`,
		})
			.then(res => {
				if (Array.isArray(res?.data?.fields)) {
					// return list of fields, extract the answers if they exist
					return {
						...res?.data,
						fields: res.data.fields.map(field => ({
							...field,
							value:
								res?.data?.drill?.log?.answers?.find(
									({ fieldUuid }) => fieldUuid === field.uuid,
								)?.value || null,
						})),
					};
				}

				throw res;
			})
			.catch(err => err?.error?.description || GENERIC_ERROR);
	},
	async scheduleAndLogADrill(buildingId, year, drillTypeUUID, date) {
		return HttpService({
			method: 'POST',
			route: '/drills/schedule-log',
			body: {
				buildingId,
				year,
				typeId: drillTypeUUID,
				date: formatDateForService(date, false),
			},
		});
	},
	async postLog(drillId, scheduledDate, logDrillDate, fields) {
		return HttpService({
			method: 'POST',
			route: `/drills/${drillId}/post`,
			body: {
				scheduledDate,
				logDrillDate,
				properties: fields.map(field => ({
					fieldUuid: field.uuid,
					value: field.value,
				})),
			},
		});
	},
	fetchUpcomingDrills: (limit = 100) =>
		HttpService({
			method: 'GET',
			route: `/drills/upcoming?limit=${limit}`,
		})
			.then(res => {
				if (res?.data?.drills?.length > 0) {
					return res?.data?.drills?.map(item => {
						const status = item.status.toLowerCase();
						let timestamp;

						if (item?.scheduledDrillDate?.date) {
							const date = new Date(item?.scheduledDrillDate?.date);
							const timeRemaining = formatDistance(date, new Date());
							timestamp = `${format(
								date,
								'MM/dd/yyyy hh:mm a',
							)}, due in ${timeRemaining}`;
						}

						return {
							...item,
							status,
							timestamp,
						};
					});
				}

				throw res;
			})
			.catch(() => GENERIC_ERROR),
	fetchOverdueDrills: (limit = 100, maxDaysOverdue = null) =>
		HttpService({
			method: 'GET',
			route: `/drills/overdue?limit=${limit}${
				maxDaysOverdue ? `&maxDaysOverdue=${maxDaysOverdue}` : ''
			}`,
		})
			.then(res =>
				res?.data?.drills?.map(item => {
					let timestamp;
					let daysOverDueDifference;
					if (item?.scheduledDrillDate?.date) {
						const date = new Date(item?.scheduledDrillDate?.date);
						const timeRemaining = formatDistance(date, new Date());
						const currentDate = new Date();
						const timeDifference = currentDate.getTime() - date.getTime();
						daysOverDueDifference = timeDifference / (1000 * 60 * 60 * 24);
						timestamp = `${format(
							date,
							'MM/dd/yyyy hh:mm a',
						)}, ${timeRemaining} past due`;
					}

					return {
						...item,
						timestamp,
						status: item.status.toLowerCase(),
						daysOverDueDifference,
					};
				}),
			)
			.catch(() => GENERIC_ERROR),
	deleteScheduledDrill: scheduleId => {
		if (!Number.isInteger(scheduleId) || scheduleId < 0) {
			throw new Error(
				`'scheduleId' needs to be a positive integer/number, not a ${typeof scheduleId}`,
			);
		}

		return HttpService({
			method: 'DELETE',
			route: `/drills/${scheduleId}`,
		}).then(res => {
			if (res?.data === 'success') return true;

			throw res;
		});
	},
	updateAndCreateDrills: (buildingId, year, currentDrills = [], newDrills = []) =>
		HttpService({
			method: 'POST',
			route: '/drills/update-or-create',
			body: {
				buildingId,
				year,
				currentDrills: currentDrills.map(({ scheduleId, date }) => ({
					scheduleId, // not a typo, used 'scheduledId'
					date: formatDateForService(date, false),
				})),
				newDrills: newDrills.map(item => ({
					drillTypeUUID: item.drillType.value,
					date: formatDateForService(item.date, false),
				})),
			},
		}).then(res => {
			if (Array.isArray(res?.data?.newDrills) || Array.isArray(res?.data?.updatedDrills)) {
				return {
					newDrills: deserializeDrills(res.data.newDrills),
					updatedDrills: deserializeDrills(res.data.updatedDrills),
				};
			}

			throw GENERIC_ERROR;
		}),
	copyDrills: (year, buildingFrom, buildingTo) =>
		HttpService({
			method: 'POST',
			route: '/drills/copy',
			body: {
				year,
				buildingFrom,
				buildingTo,
			},
		}).then(res => {
			if (Array.isArray(res?.data?.drills)) {
				return res.data.drills;
			}

			throw GENERIC_ERROR;
		}),
	getDrillsData: filterationDataObject =>
		HttpService({
			method: 'POST',
			route: '/drills/export/logs',
			body: filterationDataObject,
		}).then(res => {
			if (res.statusCode === 201) {
				return res;
			}
			throw GENERIC_ERROR;
		}),
	exportCompletedDrills: drillsFilterationData =>
		HttpService({
			method: 'POST',
			route: `/drills/completed/export`,
			body: drillsFilterationData,
		}),
};
