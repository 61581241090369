import React, { useState } from 'react';
import { FileUpload, FormModal } from 'componentsV2';
import './css/UploadImage.scss';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const UploadImage = ({
	isOpen,
	closeModal,
	activeSection,
	sectionIndex,
	subsectionIndex,
	updateNodes,
	saveTemplate,
	activeEditor,
	templateId,
}) => {
	const [loading, setLoading] = useState();
	const [selectedFile, setSelectedFile] = useState();
	const [fileError, setFileError] = useState(null);
	const [isAddVisiable, setIsAddVisiable] = useState(false);

	const onFileInputChange = e => {
		setSelectedFile(e);
		setFileError(null);
	};

	const uploadFile = () => {
		setLoading(true);
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const img = new Image();
		img.src = window.URL.createObjectURL(selectedFile);
		const formData = new FormData();
		formData.append('file', selectedFile);
		formData.append('path', `safety-plans/${templateId}/embeds`);

		fetch(`${process.env.API_URL}/secure-images`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		})
			.then(res => res.json())
			.then(resp => {
				console.log('resp', resp);
				let newContent = activeSection.content;
				const image = `<img src="${resp.data.src}" width="${img.width}" height="${
					img.height
				}" />`;
				if (activeEditor?.current) {
					// Insert the image in the current cursor position
					activeEditor.current.insertContent(image);
				} else {
					newContent += image;
					updateNodes(sectionIndex, subsectionIndex, newContent);
					setLoading(false);
				}
				closeModal();
			});
	};

	const handleProgressCompleted = isProgressCompleted => {
		if (isProgressCompleted) {
			setIsAddVisiable(true);
		} else {
			setIsAddVisiable(false);
		}
	};

	return (
		<FormModal
			isOpen={isOpen}
			onClose={() => closeModal()}
			title={'Add image'}
			size="small"
			showCloseButton={false}
			loading={loading}
			customClassName={'upload-modal-container'}
			confirmBtn={{
				title: `Add`,
				onClick: uploadFile,
				disabled: false,
				className: isAddVisiable ? '' : 'add-button-hidden',
			}}
			cancelBtn={{
				title: 'Cancel',
				type: isAddVisiable ? 'tertiary' : 'primaryDefault',
				onClick: closeModal,
			}}
		>
			<div className="col-md-12">
				<FileUpload
					disabled={false}
					required={true}
					allowedFiles={['png', 'jpeg', 'gif', 'jpg']}
					allowedFilesLegend={`PNG, JPEG, GIF (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
					// defaultErrorMessage = 'Upload failed, please try again'
					label="Image"
					labelState={false}
					onFileInputChange={onFileInputChange}
					setProgressCompleted={handleProgressCompleted}
					maxFileSize={MAX_FILE_UPLOADER_SIZE}
					error={fileError ? true : false}
					defaultErrorMessage={fileError}
				/>
			</div>
		</FormModal>
	);
};
